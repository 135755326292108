import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { signOut as amplifySignOut, fetchAuthSession, JWT } from 'aws-amplify/auth'
import { createContext, JSX, useCallback, useEffect, useState } from 'react'

interface AuthContextType {
  jwt?: JWT
  setSession: () => void
  signOut: () => void
  setToast: React.Dispatch<React.SetStateAction<JSX.Element | undefined>>
}

export const AuthContext = createContext<AuthContextType>({
  setSession: () => {},
  signOut: () => {},
  setToast: () => {},
})

interface AuthProviderType {
  children: React.ReactNode
}

export default function AuthProvider({ children }: AuthProviderType) {
  const [loading, setLoading] = useState<boolean>(true)
  const [jwt, setJwt] = useState<JWT>()
  const [toast, setToast] = useState<JSX.Element>()

  const signOut = useCallback(async () => {
    await amplifySignOut()
    setJwt(undefined)
    setLoading(false)
  }, [setJwt, setLoading])

  const setSession = useCallback(async () => {
    try {
      const session = await fetchAuthSession()
      setJwt(session.tokens?.idToken)
      setLoading(false)
    } catch {
      await signOut()
    }
  }, [setJwt, setLoading, signOut])

  useEffect(() => {
    void setSession()
  }, [setSession])

  const client = new ApolloClient({
    link: setContext((_, { headers }) => ({
      headers: {
        ...headers,
        Authorization: `Bearer ${jwt}`,
      },
      // eslint-disable-next-line unicorn/prefer-spread
    })).concat(
      createHttpLink({
        uri: process.env.REACT_APP_GRAPHQL_API_URL,
      })
    ),
    cache: new InMemoryCache(),
  })

  if (loading) {
    return <p>Loading...</p>
  }

  return (
    <AuthContext.Provider value={{ jwt, setSession, signOut, setToast }}>
      <>
        <ApolloProvider client={client}>{children}</ApolloProvider>
        {toast}
      </>
    </AuthContext.Provider>
  )
}
