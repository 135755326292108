import { useQuery, gql, useMutation } from '@apollo/client'
import { useState } from 'react'
import Table from 'react-bootstrap/Table'

const PRODUCTS = gql`
  query {
    products {
      code
      name {
        long
      }
      fare
      version
      validAt
    }
  }
`

const CREATE_ORDER = gql`
  mutation CreateOrder($create: OrderInput!) {
    createOrder(input: $create) {
      ... on OrderCreation {
        id
      }
      ... on Failure {
        __typename
        errors {
          description
        }
      }
    }
  }
`

const CREATE_CONTEXT = gql`
  mutation CreateContext($create: ContextCreateRequestInput!) {
    createContext(input: $create) {
      ... on ContextCreateResponse {
        __typename
        context
      }
      ... on Failure {
        __typename
        errors {
          description
        }
      }
    }
  }
`

const Products = () => {
  const [context, setContext] = useState<string>('')
  const [order, setOrder] = useState<string>('')

  const { data, loading: loadingProducts } = useQuery(PRODUCTS)
  const [createOrder, { loading: loadingCreateOrder }] = useMutation(CREATE_ORDER)
  const [createContext, { loading: loadingCreateContext }] = useMutation(CREATE_CONTEXT)

  const handleOrderClick = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    product: { code: string; version: string }
  ) => {
    event.preventDefault()
    await createOrder({
      variables: {
        create: {
          device: {
            id: 'devFrontendId',
            userAgent: 'devFrontendUserAgent',
          },
          lineItems: {
            product: {
              code: product.code,
              version: product.version,
            },
            quantity: 1,
          },
        },
      },
      onCompleted: async (data) => {
        if (data.createOrder.id) {
          setOrder(data.createOrder.id)
          await createContext({
            variables: {
              create: {
                orderId: data.createOrder.id,
              },
            },
            onCompleted: (data) => {
              setContext(data.createContext.context)
            },
          })
        }
      },
    })
  }

  const handleContextClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, orderId: string) => {
    event.preventDefault()
    await createContext({
      variables: {
        create: {
          orderId,
        },
      },
      onCompleted: (data) => {
        setContext(data.createContext.context)
      },
    })
  }

  if (!loadingProducts) {
    const processingOrder = loadingCreateOrder || loadingCreateContext
    return (
      <>
        <h1 className="mb-4">Products</h1>

        {order ? (
          <>
            <h2>Order</h2>
            <code>{order}</code>
            <button disabled={processingOrder} onClick={(e) => handleContextClick(e, order)}>
              Create context
            </button>
          </>
        ) : (
          <>
            <p>Order will appear here after purchase...</p>
          </>
        )}

        {context ? (
          <>
            <h2>Context</h2>
            <code>{context}</code>
          </>
        ) : (
          <p>Context will appear here after purchase...</p>
        )}

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Code</th>
              <th>Version</th>
              <th>Fare</th>
              <th>Valid at</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.products.map(
              (product: { code: string; name: { long: string }; version: string; fare: number; validAt: string }) => (
                <tr key={product.code}>
                  <td>{product.name.long}</td>
                  <td>{product.code}</td>
                  <td>{product.version}</td>
                  <td>{product.fare}</td>
                  <td>{product.validAt}</td>
                  <td>
                    <button disabled={processingOrder} onClick={(e) => handleOrderClick(e, product)}>
                      Purchase
                    </button>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      </>
    )
  }
}

export default Products
