import { useQuery, gql } from '@apollo/client'
import Table from 'react-bootstrap/Table'

const ORDERS = gql`
  query {
    orders {
      created
      id
      lineItems {
        product {
          code
          name {
            long
          }
        }
      }
    }
  }
`

const Orders = () => {
  const { data, loading } = useQuery(ORDERS)

  if (!loading) {
    return (
      <>
        <h1 className="mb-4">Orders</h1>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Created</th>
              <th>Product name(s)</th>
              <th>Product code(s)</th>
            </tr>
          </thead>
          <tbody>
            {data.orders.map(
              (order: {
                id: string
                lineItems: { product: { name: { long: string }; code: string } }[]
                created: string
              }) => (
                <tr>
                  <td>{order.id}</td>
                  <td>{order.created}</td>
                  <td>{order.lineItems.map(({ product }) => product.name.long).join(', ')}</td>
                  <td>{order.lineItems.map(({ product }) => product.code).join(', ')}</td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      </>
    )
  }
}

export default Orders
