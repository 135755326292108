import { signIn } from 'aws-amplify/auth'
import { FormEvent, useContext, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useNavigate } from 'react-router-dom'

import { AuthContext } from './auth'

export default function SignIn() {
  const { setSession } = useContext(AuthContext)
  const navigate = useNavigate()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    try {
      await signIn({ username: email, password })
      setSession()
      navigate('/account')
    } catch (error) {
      console.log('signIn', error)
    }
  }

  return (
    <>
      <h1 className="mb-4">Sign in</h1>
      <p>
        Don't have an account? <a href="/register">Register here</a>
      </p>
      <p>
        Forgot your password? <a href="/forgot-password">Reset it here</a>
      </p>

      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" value={email} required onChange={(e) => setEmail(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" value={password} required onChange={(e) => setPassword(e.target.value)} />
        </Form.Group>
        <Button type="submit">Sign in</Button>
      </Form>
    </>
  )
}
