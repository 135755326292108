import { useContext } from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Account from './account'
import { AuthContext } from './auth'
import ForgotPassword from './forgot-password'
import Home from './home'
import Orders from './orders'
import PrivateRoute from './private-route'
import Products from './products'
import Register from './register'
import SignIn from './sign-in'

export default function App() {
  const { jwt, signOut } = useContext(AuthContext)

  return (
    <BrowserRouter>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="/">Honeycomb - {process.env.REACT_APP_STAGE}</Navbar.Brand>
          <Nav>
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/products">Products</Nav.Link>
            {jwt ? (
              <>
                <Nav.Link href="/orders">Orders</Nav.Link>
                <Nav.Link href="/account">Account</Nav.Link>
                <Nav.Link onClick={signOut}>Sign out</Nav.Link>
              </>
            ) : (
              <>
                <Nav.Link href="/sign-in">Sign in</Nav.Link>
                <Nav.Link href="/register">Register</Nav.Link>
              </>
            )}
          </Nav>
        </Container>
      </Navbar>
      <Container className="mt-5">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/sign-in" element={<SignIn />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/products" element={<Products />} />
          <Route
            exact
            path="/account"
            element={
              <PrivateRoute>
                <Account />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/orders"
            element={
              <PrivateRoute>
                <Orders />
              </PrivateRoute>
            }
          />
        </Routes>
      </Container>
    </BrowserRouter>
  )
}
