import { signIn, signUp, confirmSignUp } from 'aws-amplify/auth'
import { FormEvent, useContext, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useNavigate } from 'react-router-dom'

import { AuthContext } from './auth'

export default function Register() {
  const { setSession } = useContext(AuthContext)
  const navigate = useNavigate()
  const [showVerify, setShowVerify] = useState<boolean>(false)
  const [firstName, setFirstName] = useState<string>('First')
  const [lastName, setLastName] = useState<string>('Last')
  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string>('+440000000000')
  const [dateOfBirth, setDateOfBirth] = useState<string>('1990-01-01')
  const [password, setPassword] = useState<string>('Pass123!')
  const [marketingOptIn, setMarketingOptIn] = useState<boolean>(false)
  const [tAndCAgreed, setTAndCAgreed] = useState<boolean>(true)
  const [verification, setVerification] = useState<string>('')

  const handleRegister = async (event: FormEvent) => {
    event.preventDefault()
    try {
      await signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            given_name: firstName,
            family_name: lastName,
            phone_number: phone,
            birthdate: dateOfBirth,
            'custom:marketingOptIn': marketingOptIn.toString(),
            'custom:tAndCAgreed': tAndCAgreed.toString(),
          },
        },
      })
      setShowVerify(true)
    } catch (error) {
      console.log('signUp', error)
    }
  }

  const handleVerification = async (event: FormEvent) => {
    event.preventDefault()
    try {
      await confirmSignUp({ username: email, confirmationCode: verification })
      await signIn({ username: email, password })
      setSession()
      navigate('/account')
    } catch (error) {
      console.log('confirmSignUp error', error)
    }
  }

  const now = new Date()
  now.setFullYear(now.getFullYear() - 13)
  const formattedMaxDate = now.toLocaleDateString('fr-ca')

  return showVerify ? (
    <>
      <h1>Verify</h1>
      <p>An email has been sent to {email} containing a code to verify your email address.</p>
      <Form onSubmit={handleVerification}>
        <Form.Group className="mb-3" controlId="verification">
          <Form.Label>Verification code</Form.Label>
          <Form.Control type="text" value={verification} onChange={(e) => setVerification(e.target.value)} />
        </Form.Group>
        <Button type="submit">Sign in</Button>
      </Form>
    </>
  ) : (
    <>
      <h1 className="mb-4">Register</h1>
      <p>
        Already have an account? <a href="/sign-in">Sign in here</a>
      </p>
      <Form onSubmit={handleRegister}>
        <Form.Group className="mb-3" controlId="firstName">
          <Form.Label>First name</Form.Label>
          <Form.Control type="text" value={firstName} required onChange={(e) => setFirstName(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="lastName">
          <Form.Label>Last name</Form.Label>
          <Form.Control type="text" value={lastName} required onChange={(e) => setLastName(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" value={email} required onChange={(e) => setEmail(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="phone">
          <Form.Label>Phone</Form.Label>
          <Form.Control
            type="text"
            value={phone}
            required
            pattern="^(\+44) ?\d{10}$"
            onChange={(e) => setPhone(e.target.value)}
          />
          <Form.Text id="passwordHelpBlock" muted>
            Must start with +44 followed by 10 numbers with no spaces or special characters
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="dateOfBirth">
          <Form.Label>Date of birth (DD/MM/YYYY)</Form.Label>
          <Form.Control
            type="date"
            value={dateOfBirth}
            required
            pattern="\d{1,2}/\d{1,2}/\d{4}"
            max={formattedMaxDate}
            onChange={(e) => setDateOfBirth(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" value={password} required onChange={(e) => setPassword(e.target.value)} />
          <Form.Text muted>
            Minimum 8 characters including at least one of each of the following: uppercase letter, lowercase letter,
            number and special character
          </Form.Text>
        </Form.Group>
        <Form.Check
          className="mb-3"
          type="checkbox"
          id="marketingOptIn"
          label="Marketing opt in"
          checked={marketingOptIn}
          onChange={() => setMarketingOptIn(!marketingOptIn)}
        />
        <Form.Check
          className="mb-3"
          type="checkbox"
          id="tAndCAgreed"
          label="Terms and conditions"
          checked={tAndCAgreed}
          required
          onChange={() => setTAndCAgreed(!tAndCAgreed)}
        />
        <Button type="submit">Register</Button>
      </Form>
    </>
  )
}
